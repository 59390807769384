import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'


export const certifiedList = (code, name, supplier, allSections, location, page, pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetCertifiedVendors",
        method: "get",
        params: {
            "code": code,
            "name": name,
            "supplier": supplier,
            "allSections": allSections,
            "location": location,
            "page": page,
            "pageSzie": pageSize
        }
    })
}

//
export const addCer = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostCertifiedVendor",
        method: "post",
        data: {
            ...row
        }
    })
}

//
export const editCer = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PutCertifiedVendor",
        method: "put",
        data: {
            ...row
        }
    })
}

//
export const delCer = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteCertifiedVendor",
        method: "delete",
        params: {
                id
        }
    })
}

export const editStatus = (row) => {
    return request({
        url: pvhApiBase + "Tracing/UpdateCertifiedStatus",
        method: "put",
        data: {
            BrandBusinessId:"",
            CertifiedId: row.id,
            Status: row.Status == 1 ? false : true
        }
    })
}


export const SupplierVendorList = (code, name, type, page, pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetSupplierVendors",
        method: "get",
        params: {
            "code": code,
            "name": name,
            "type": type,
            "page": page,
            "pageSzie": pageSize
        }
    })
}